.App {
  text-align: center;
  background-color: #27374D;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.timer-controls {
    padding-top: 1.5rem;
}

@font-face {
    font-family: "7-Segment";
    src: local("7-Segment"),
        url("./fonts/7seg/7seg.ttf") format("truetype");
}

.score {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
