.timer-controls {
    display: flex;
    justify-content: center;
    align-items: center;
}

.timer-controls button:hover {
    cursor: pointer;
}

.timer-controls .glow-orange {
    width: 100px;
    background: transparent;
    font-family: 'Roboto', sans-serif;
    border: none;
    box-shadow:
        0px 0px 1px #fff,
        0px 0px 2px #fff,
        0px 0px 5px #ff6600,
        0px 0px 7px #ff6600,
        0px 0px 9px #ff6600,
        0px 0px 5px #ff6600 inset,
        0px 0px 7px #ff6600 inset,
        0px 0px 9px #ff6600 inset;
    font-size: 2rem;
    color: aliceblue;
    border-radius: 5%;
    margin: 3px;
    text-shadow:
        0 0 2px #fff,
        0 0 4px #fff,
        0 0 9px #ff6600,
        0 0 11px #ff6600,
        0 0 13px #ff6600;
}

.timer-controls .glow-green {
    width: 100px;
    background: transparent;
    font-family: 'Roboto', sans-serif;
    border: none;
    box-shadow:
        0px 0px 1px #fff,
        0px 0px 2px #fff,
        0px 0px 5px #00ff00,
        0px 0px 7px #00ff00,
        0px 0px 9px #00ff00,
        0px 0px 5px #00ff00 inset,
        0px 0px 7px #00ff00 inset,
        0px 0px 9px #00ff00 inset;
    font-size: 2rem;
    color: aliceblue;
    border-radius: 5%;
    margin: 3px;
    text-shadow:
        0 0 2px #fff,
        0 0 4px #fff,
        0 0 9px #00ff00,
        0 0 11px #00ff00,
        0 0 13px #00ff00;
}

.timer-controls .glow-red {
    width: 100px;
    background: transparent;
    font-family: 'Roboto', sans-serif;
    border: none;
    box-shadow:
        0px 0px 1px #fff,
        0px 0px 2px #fff,
        0px 0px 5px #ff0000,
        0px 0px 7px #ff0000,
        0px 0px 9px #ff0000,
        0px 0px 5px #ff0000 inset,
        0px 0px 7px #ff0000 inset,
        0px 0px 9px #ff0000 inset;
    font-size: 2rem;
    color: aliceblue;
    border-radius: 5%;
    margin: 3px;
    text-shadow:
        0 0 2px #fff,
        0 0 4px #fff,
        0 0 9px #ff0000,
        0 0 11px #ff0000,
        0 0 13px #ff0000;
}
