.glow {
    display: flex;
    justify-content: center;
    color: #fff;
    text-align: center;
    text-shadow:
        0 0 2px #fff,
        0 0 4px #fff,
        0 0 9px #ff6600,
        0 0 11px #ff6600,
        0 0 13px #ff6600;
}

.timer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.timer span {
    font-size: 8rem;
}

.normal-font {
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
}

.segment-font {
    font-family: '7-Segment', sans-serif;
}
