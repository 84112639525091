.counter span {
    font-size: 8rem;
    font-family: '7-Segment', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.counter button {
    background: transparent;
    border: none;
    box-shadow: none;
    font-size: 5rem;
}

.vertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.glow-red {
    color: #fff;
    text-align: center;
    text-shadow:
        0 0 2px #fff,
        0 0 4px #fff,
        0 0 9px #e60073,
        0 0 11px #e60073,
        0 0 13px #e60073;
}

.glow-blue {
    color: #fff;
    text-align: center;
    text-shadow:
        0 0 2px #fff,
        0 0 4px #fff,
        0 0 9px #00b3e6,
        0 0 11px #00b3e6,
        0 0 13px #00b3e6;
}
